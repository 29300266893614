import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import Worldomeeter from "./Components/Worldomeeter";
import MontyHall from "./Components/MontyHall";

function App() {
  
  return (
    <div className="" >
        <BrowserRouter>  
          
          <Routes>
            <Route  path='/' element={< Home />}></Route>
            <Route  path='/Worldomeeter' element={< Worldomeeter />}></Route>
            <Route  path='/MontyHall' element={< MontyHall />}></Route>
          
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
