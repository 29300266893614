import React from 'react'

export default function MontyHall() {
    return (
        <div className='flex flex-col items-center mt-20 font-FontBold'>
            <div className='max-w-4xl '>
                <h1 className='text-2xl md:text-3xl lg:text-4xl  text-[#FFF501]'>What is the problem?</h1>
                <p className=' text-xl mt-2'>The Monty Hall problem is a famous probability puzzle named after the host of the American television game show "Let's Make a Deal," Monty Hall. The problem is as follows:

                    You are a contestant on a game show, and there are three doors in front of you. Behind one of the doors is a valuable prize, such as a car, while the other two doors conceal less desirable prizes, such as goats. You want to win the car.

                    Here's how the game unfolds:
                </p>

                <ol className='text-xl  text-[#3c8af0] mt-4'>
                    <li>1. You choose one of the three doors, let's say Door 1, without knowing what is behind it.</li>
                    <li className='my-2'>2. After you make your choice, the host, Monty Hall, who knows what is behind each door, opens one of the remaining doors that you did not choose, revealing a goat. For example, if you chose Door 1 and the car is behind Door 2, Monty will open Door 3 to reveal a goat.</li>
                    <li>3. At this point, you have a choice to make: stick with your original choice (Door 1) or switch your choice to the other unopened door (Door 2).</li>
                </ol>

                <h1 className='text-2xl md:text-3xl lg:text-4xl  text-[#FFF501] mt-3'>Should you stick with your choice or switch it?</h1>
                <p className=' text-xl mt-2'>Intuitively, some people believe that it doesn't matter whether you stick with your original choice or switch, as each unopened door now has an equal chance of hiding the car. However, the optimal strategy is to switch doors. The probability of winning if you switch is 2/3, while the probability of winning if you stick with your original choice is 1/3.

                    To understand why switching is the better strategy, consider the possible scenarios:
                </p>
                <ol className='text-xl  text-[#3c8af0] mt-4'>
                    <li>1. If you initially chose the door with the car (1/3 probability), switching will make you lose.</li>
                    <li className='my-2'>2.If you initially chose a door with a goat (2/3 probability), switching will make you win.</li>
                </ol>
                <p className=' '>Since there are twice as many scenarios where you initially choose a door with a goat, switching will increase your chances of winning the car to 2/3.


                </p>

                <h1 className='text-2xl md:text-3xl lg:text-4xl  text-[#FFF501] mt-10'>Testing our strategy</h1>
                <img src={require("../res/MontyHall.png")} height={400} alt="" />
                <h1 className='text-2xl md:text-3xl lg:text-4xl  text-[#FFF501] mt-10'>Test results</h1>
                <img src={require("../res/Results.png")} height={400} alt="" />
                <h1 className='text-2xl md:text-3xl lg:text-4xl  text-[#FFF501] mt-10'>Conclusion</h1>
                <p className='text-xl mt-2'>This result may seem counterintuitive, as it goes against our intuition that the probabilities should be evenly distributed after Monty reveals a goat. However, the key is that Monty's action of revealing a goat provides you with additional information about the remaining doors, and it changes the probabilities in a non-obvious way.

                    The Monty Hall problem is a classic example in probability theory and often used to illustrate concepts such as conditional probability and the role of information in decision-making.</p>
            </div>
        </div>
    )
}
