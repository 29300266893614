import React from "react";

export default function Worldomeeter() {
  return (
    <div className="flex flex-col items-center font-FontBold mt-10 overflow-x-hidden">
      <div className="max-w-4xl text-center">
        <h1 className="text-3xl md:text-4xl mb-4 ">Webscraper Project</h1>
        <p className="text-base  lg:text-xl text-[#bbbbbb]">
          With the rise of data science and analytics, web scraping has become
          an increasingly popular tool for collecting information from websites.
          Web scraping is the process of automatically extracting data from
          websites, and it can be a powerful way to gather information for data
          analysis or research. In this project, I will walk through the process
          of how I build a web scraper using JavaScript and Pupeteer to extract
          information from Worldomeeter.
        </p>
      </div>


      <div className="max-w-4xl text-center">
        <h1 className="text-xl md:text-2xl mt-4 "><span className="text-[#FFF501]">Step 1:</span> Inspect the Website</h1>
        <p className="text-base  lg:text-xl text-[#bbbbbb]">
          Every item is saved within a span. The name has the class “.counter-item” and the counter itself has the class “.counter-number”. Items with a larger number have the class “.counter-item-double”.
        </p>
      </div>

      <div className="max-w-4xl text-center flex flex-col items-center my-4">
        <h1 className="text-xl md:text-2xl mt-4 "><span className="text-[#FFF501]">Step 2:</span> Set up Routes</h1>
        <p className="text-base  lg:text-xl text-[#bbbbbb]">
          In order to push the items automatically to my database I need to be able to receive HTTP requests. Thus I set up Routes to a Node.js and Express.js which I created in the third step
        </p>
        <img src={require(`../res/worldomeeter/image2.png`)} width={500} alt="Code screenshot with explanation" className="ml-5 mt-8" />
        <p className="text-[#f13c3c]">
          Important!: These Routes are not protected, therefore it is not recommended to use them publically
        </p>
      </div>

      <div className="max-w-4xl text-center">
        <h1 className="text-xl md:text-2xl mt-4 "><span className="text-[#FFF501]">Step 3:</span> Set up server and database connection</h1>
        <div className="flex flex-col items-center lg:justify-center lg:flex-row">
          <img src={require(`../res/worldomeeter/image3.png`)} width={700} className='mx-4 lg:my-0 my-4' alt="" />
          <img src={require(`../res/worldomeeter/image4.png`)} width={510} className='mx-4 lg:my-0 my-4' alt="" />
        </div>
        <p className="text-base  lg:text-xl text-[#bbbbbb]">
          This code establishes a connection to my database as well as a server with the routes I just created.
        </p>
      </div>

      <div className="max-w-4xl text-center">
        <h1 className="text-xl md:text-2xl mt-4 "><span className="text-[#FFF501]">Step 4: </span> Create a model</h1>
        <p className="text-base  lg:text-xl text-[#bbbbbb]">
          To post data we need to create a Schema.
        </p>
        <img src={require(`../res/worldomeeter/image5.png`)} alt="" />
      </div>

      <div className="max-w-4xl text-center">
        <h1 className="text-xl md:text-2xl mt-4 "><span className="text-[#FFF501]">Final Step: </span> Code and run the Webscraper</h1>
        <p className="text-base  lg:text-xl text-[#bbbbbb]">
          First of all I need to tell puppeteer to go to the page. Afterwards it grabs all of the counters we inspected. We than grab the text inside of the element and categorize it. Finally we assemble all of the items to a final object. Read the comments in the code to see what the code is exactly doing. Otherwise check out the <a className="underline text-[#FFF501]" target='_blank' rel="noreferrer" href="https://github.com/Jxnas999/Worldometer-Scraper-REST">Git Hub repository</a> for more explanation.
        </p>
        <div className="flex flex-col items-center">
          <img src={require(`../res/worldomeeter/imag6.png`)} width={900} className='m-4' alt="" />
          <p className="text-base  lg:text-xl text-[#bbbbbb]">
            We categorize the items based on their position in the array. Also possible with a switch statement but I chose a simple else if condition.
          </p>
          <img src={require(`../res/worldomeeter/image6.png`)} width={800} className='m-4' alt="" />
          <p className="text-base  lg:text-xl text-[#bbbbbb]">
            Now we ask the user if he wants to update already existing data or if he wants to initialize the database.
          </p>
          <img src={require(`../res/worldomeeter/image7.png`)} width={800} className='m-4' alt="" />
          <p className="text-base  lg:text-xl text-[#bbbbbb]">
            Now we just need two functions one to post to the database for the first time and one to update the info which is already inside
          </p>
          <img src={require(`../res/worldomeeter/image8.png`)} width={800} className='m-4' alt="" />
        </div>
      </div>

      <div className="max-w-4xl text-center">
        <h1 className="text-xl md:text-2xl mt-4 mb-10"><span className="text-[#FFF501]">You made it to the end!</span> Thanks for reading❤️</h1>
      </div>
    </div>
  );
}