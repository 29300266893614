import { useState } from "react";
import { Link } from "react-router-dom";


function Home() {
  const [creds, setCreds] = useState<Boolean>(false)
  const [cryptoCreds, setCredsCrypto] = useState<Boolean>(false)
  return (
    <div className="font-FontNormal  ">
      <section className="snap-start h-screen ">
        <div className="mt-4 text-xl sm:text-xl md:text-2xl flex justify-center">
          <div className="flex mr-8 items-center hover:-translate-y-1 duration-300">
            <img
              src={require("../res/code.png")}
              height={30}
              width={45}
              alt=""
            />
            <a className="hover:text-[#FFF501]" href="#skills">
              Skills
            </a>
          </div>

          <div className="flex items-center hover:-translate-y-1 duration-300">
            <img
              src={require("../res/lebenslauf.png")}
              height={30}
              width={45}
              alt=""
            />
            <a href="#work" className="hover:text-[#FFF501]">
              Work
            </a>
          </div>

          <div className="flex ml-8 items-center  hover:-translate-y-1 duration-300">
            <img
              src={require("../res/telefon.png")}
              height={30}
              width={45}
              alt=""
            />
            <a href="#contact" className="hover:text-[#FFF501]">
              Contact
            </a>
          </div>
        </div>

        <div className="flex flex-col mt-10">
          <div className="flex justify-center mt-14 ml-4">
            <div className="text-xl md:text-2xl lg:text-3xl flex justify-center items-center flex-col md:mr-10 md:ml-10">
              <h1 className="self-start  text-3xl md:text-4xl lg:text-5xl font-FontBold">
                Hi, Im Jonas <div className="hand inline-block">👋</div>
              </h1>
              <p className="mt-4">
                I'm an independent <b>full stack</b> developer based in Cologne,
                Germany 📍
              </p>
              <hr className="w-full p-2 md:p-4 rounded-2xl mb-2 mt-4 bg-white "></hr>
              <p className="text-[#FFF501] drop-shadow-2xl self-start text-xl font-FontPlayfair font-bold">Learning and improving is what keeps us valuable. It gives life purpose</p>

            </div>
            <div className=" flex items-center">
              <img
                className="w-[300px] customani"
                src={require("../res/Profile.jpeg")}
                alt="Profile"
              />
            </div>            
          </div>
          <div className="self-center h-[330px]  bg-white w-[5px] mb-4"></div>
          
        </div>
      </section>

      <section id="work">
        <div className="flex items-center flex-col  text-center">
          <h1 className="text-2xl md:text-3xl lg:text-4xl font-FontBold">
            Open Source Projects I build
          </h1>
          <div className="text-black  mt-10 text-xl md:text-xl lg:text-2xl w-full max-w-[1000px] px-4">
            <h1
              className="bg-[#FFF501] rounded-md p-3 mt-10 font-FontBold"
            >
              Webscraper API
            </h1>
            <div className="text-white">
              <div className="mt-4 md:flex-row flex-col-reverse flex items-center">

                <video className="ml-4 lg:max-w-[450px] max-w-[400px]" src={require("../res/WebscraperVid.mp4")} autoPlay loop muted>
                  Your Browser does not support Video
                </video>
                <div className="flex flex-col">
                  <h1 className="font-FontBold">REST API 🌐</h1>
                  <p className="text-base text-[#bbbbbb]">
                    The Website{" "}
                    <a
                      className="underline text-[#FFF501]"
                      href="https://www.worldometers.info/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      worldometer
                    </a>{" "}
                    holds over 50 different real time statistics, such as the
                    number of car accidents this year, the number of deaths
                    this year, the amount of money spent on video games this
                    year etc. Because they have no API to use the data in
                    another Web Application, I created my own REST API. Ive
                    also created a YouTube{" "}
                    <a
                      className="text-[#FFF501] underline"
                      href="https://www.youtube.com/watch?v=eJeyhA_9Diw&ab_channel=JTech"
                      target="_blank"
                      rel="noreferrer"
                    >
                      tutorial
                    </a>{" "}
                    on how to use the puppeteer library.
                  </p>
                  <Link to='/Worldomeeter'>
                    <button className="text-base font-FontBold bg-[#FFF501] text-black rounded-full px-3 hover:bg-[#ffff74]  duration-300 hover:px-4" ><Link to='/Worldomeeter'>Complete Documentation & Approach</Link></button>
                  </Link>
                  <a className="self-center" target='_blank' rel="noreferrer" href="https://github.com/Jxnas999/Worldometer-Scraper-REST">
                    <img
                      src={require("../res/github.png")}
                      className="h-[50px]  hover:-translate-y-1 duration-300"
                      alt="github"
                    />
                  </a>
                </div>
              </div>
            </div>

            <h1
              className={`p-3 bg-[#FFF501]   mt-10 font-FontBold`}>
              CRUD Book Notes App
            </h1>
            <div className="text-white">

              <div className="mt-4 md:flex-row flex-col flex items-center">
                <img
                  src={require("../res/booksite.png")}
                  className="aspect-video rounded-3xl mr-4"
                  width={400}
                  height={100}
                  alt=""
                />
                <div className="flex flex-col">
                  <h1 className="font-FontBold">Book Notes📚</h1>
                  <p className="text-[#bbbbbb] text-base">
                    In{" "}
                    this
                    Project I created an App in which you can add books or
                    podcasts to your profile. You are then able to add and
                    delete notes to the specific item you added. I created
                    this Website because if I read a book or listen to a
                    podcast, I sometimes forget some key parts of it.
                    Therefore, I can easily create a note and read everything I
                    added later on.
                  </p>


                  <div className="flex justify-center">

                    <a className="" target='_blank' rel="noreferrer" href="https://github.com/Jxnas999/BookSite-CRUD">
                      <img
                        src={require("../res/github.png")}
                        className="h-[50px]   hover:-translate-y-1 duration-300"
                        alt="github"
                      />
                    </a>

                    <a className="" target='_blank' rel="noreferrer" href="https://644706e4cad3ce0008c28217--spiffy-toffee-3e8430.netlify.app/">
                      <img
                        src={require("../res/netlify.png")}
                        className="ml-4 h-[40px] mt-1  hover:-translate-y-1 duration-300"
                        alt="netlify"
                      />
                    </a>
                  </div>
                  <div className="flex justify-center">
                    <button onClick={e => setCreds(!creds)} className="mt-4 px-6 hover:px-8 py-1 hover:bg-[#ffff74]  duration-300 text-black bg-[#FFF501] rounded-full font-FontBold uppercase text-sm">login credentials </button>
                  </div>
                  <p className="mt-2 font-FontBold text-lg">{creds && <div><p>Mail: test@aol.com</p><p>Password: 123456</p></div>}</p>
                </div>
              </div>

            </div>

            <h1
              className={`p-3 bg-[#FFF501]   mt-10 font-FontBold`}>
              Crypto Website
            </h1>
            <div className="text-white mb-10">

              <div className=" mt-4 md:flex-row md:justify-around  flex-col flex items-center ">
                <div className="flex flex-col max-w-[400px]">
                  <h1 className="font-FontBold">Cryoto Watcher🚀</h1>
                  <p className="text-[#bbbbbb] text-base">
                    This Web-Application uses the CoinGecko API to retrieve
                    data and graphs of the current crypto market. As a user
                    you are able to create an account in order to like
                    specific currencies which you would like to keep track of.
                    You can then navigate to the Watchlist page and
                    individually view your liked currencies.

                  </p>
                  <div className="flex justify-center">

                    <a className="" target='_blank' rel="noreferrer" href="https://github.com/Jxnas999/Crypto">
                      <img
                        src={require("../res/github.png")}
                        className="h-[50px]   hover:-translate-y-1 duration-300"
                        alt="github"
                      />
                    </a>

                    <a className="" target='_blank' rel="noreferrer" href="https://dainty-malasada-219a21.netlify.app/">
                      <img
                        src={require("../res/netlify.png")}
                        className="ml-4 h-[40px] mt-1  hover:-translate-y-1 duration-300"
                        alt="netlify"
                      />
                    </a>
                  </div>
                  <div className="flex justify-center">
                    <button onClick={e => setCredsCrypto(!cryptoCreds)} className="mt-4 px-6 hover:px-8 py-1 hover:bg-[#ffff74]  duration-300 text-black bg-[#FFF501] rounded-full font-FontBold uppercase text-sm">login credentials </button>
                  </div>
                  <p className="mt-2 font-FontBold text-lg">{cryptoCreds && <div><p>Mail: test@aol.com</p><p>Password: 123456</p></div>}</p>

                </div>
                <video className="ml-4 lg:max-w-[500px] max-w-[400px] " src={require("../res/CryptoSite.mp4")} autoPlay loop muted>
                  Your Browser does not support Video
                </video>

              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="flex flex-col items-center my-20">
          <h1 className="text-2xl md:text-3xl lg:text-4xl font-FontBold">Monty Hall problem proof</h1>
          <button className="mt-4 px-6 hover:px-8 py-1 hover:bg-[#ffff74]  duration-300 text-black rounded-md bg-[#FFF501] font-FontBold  uppercase text-xl"><Link to='/MontyHall'>Code and test results</Link></button>
        </div>
      </section>

      <section id="skills">
        <div className="flex flex-col items-center">
          <div className="max-w-[1000px] w-full text-center  text-lg lg:text-2xl ">
            <div>
              <div className="flex items-center mb-4">
                <hr
                  className={`rounded-tl-lg border-0 rounded-bl-lg mx-4 w-full h-full p-3 lg:p-4 bg-gradient-to-r  from-[#FDFC47] to-[#24FE41]`}
                ></hr>
                <img
                  className="hover:-translate-y-2 duration-300"
                  src={require("../res/html-5.png")}
                  width={60}
                  alt="html-icon"
                />
                <img
                  className="hover:-translate-y-2 duration-300"
                  src={require("../res/css-3.png")}
                  width={60}
                  alt="css-icon"
                />
                <img
                  className="ml-2 hover:-translate-y-2 duration-300"
                  src={require("../res/js.png")}
                  width={43}
                  alt="javascript-icon"
                />
                <hr
                  className={`rounded-tr-lg border-0 rounded-br-lg mx-4 w-full h-full p-3 lg:p-4 bg-gradient-to-r  from-[#24FE41] to-[#FDFC47]`}
                ></hr>
              </div>
              <p className="ml-3">
                Almost every Web Developer starts with HTML CSS and JavaScript
                and so did I. Within my three-year experience of using HTML5,
                CSS3 and JavaScript I have learned a lot about the beauty of
                programming and many concepts of it. To this day Im still
                fascinated by JavaScript and excited about new Frameworks or
                Functions implemented or added to JavaScript.{" "}
              </p>
            </div>

            <div className="mt-20 mb-20">
              <div className="flex items-center mb-4">
                <hr
                  className={`rounded-tl-lg border-0 rounded-bl-lg mx-4 w-full h-full p-3 lg:p-4 bg-gradient-to-r  from-[#FDFC47] to-[#24FE41]`}
                ></hr>
                <img
                  className="hover:-translate-y-2 duration-300"
                  src={require("../res/React-icon.png")}
                  width={60}
                  alt="react-icon"
                />
                <hr
                  className={`rounded-tr-lg border-0 rounded-br-lg ml-4 w-full h-full p-3 lg:p-4 bg-gradient-to-r  from-[#24FE41] to-[#FDFC47]`}
                ></hr>
              </div>
              <p className="ml-3">
                I love React very much for various
                reasons. Its component-based architecture and virtual DOM made
                it very easy for me to get started and still get going. Its
                strong community and vast ecosystem of tools and libraries make
                it much fun building Applications with it. My experience taught
                me many things about this beautiful framework. But still I think
                I will never learn enough about react.{" "}
              </p>
            </div>

            <div>
              <div className="flex items-center mb-4">
                <hr
                  className={`rounded-tl-lg border-0 rounded-bl-lg mx-4 w-full h-full p-3 lg:p-4 bg-gradient-to-r  from-[#FDFC47] to-[#24FE41]`}
                ></hr>
                <img
                  className="hover:-translate-y-2 duration-300"
                  src={require("../res/nodejs.png")}
                  width={70}
                  alt="nodejs-icon"
                />
                <hr
                  className={`rounded-tr-lg border-0 rounded-br-lg mx-4 w-full h-full p-3 lg:p-4 bg-gradient-to-r  from-[#24FE41] to-[#FDFC47]`}
                ></hr>
              </div>
              <p className="ml-3">
                I like Node.js and Express.js because they make it easy for me
                to build fast, efficient and scalable server-side applications
                right from the beginning. The fact that Node.js is built on V8
                JavaScript engine, allows the use of JavaScript on the
                server-side, which has been especially useful for those who are
                already familiar with the language.
              </p>
            </div>

            <div className="flex flex-wrap justify-around mt-14 ">
              <div className="flex justify-center mt-10 text-2xl md:text-3xl lg:text-4xl font-FontBold flex-col items-center">
                <h1 className="mb-3">Databasesystems</h1>
                <div className="flex ">
                  <img
                    src={require("../res/firebase.png")}
                    className="mr-8 h-[40px] md:h-[70px]"
                    alt="firebase"
                  />
                  <img
                    src={require("../res/mongodb.png")}
                    className=" h-[40px] md:h-[70px]"
                    alt="mongodb"
                  />
                  <img
                    src={require("../res/MYSQL.png")}
                    className="ml-8 h-[40px] md:h-[70px]"
                    alt="MYSQL"
                  />
                </div>
              </div>

              <div className="flex  justify-center mt-10 text-2xl md:text-3xl lg:text-4xl font-FontBold flex-col items-center">
                <h1 className="mb-3">Other</h1>
                <div className="flex ">
                  <img
                    src={require("../res/redux.png")}
                    className="mr-8 h-[40px] md:h-[70px]"
                    alt="redux"
                  />
                  <img
                    src={require("../res/figma.png")}
                    className=" h-[40px] md:h-[70px]"
                    alt="figma"
                  />
                  <img
                    src={require("../res/github.png")}
                    className="ml-8 h-[40px] md:h-[70px]"
                    alt="github"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="contact">
        <div className="flex flex-col justify-center items-center h-screen">
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-FontBold mb-4">
            Contact
          </h1>
          <div className="flex items-center">
            <a
              href="https://www.linkedin.com/in/jonas-koscielny-189755231/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={require("../res/linkedin.png")}
                className="ml-8 h-[60px] hover:-translate-y-2 duration-300"
                alt="linkedin"
              />
            </a>
            <a href="https://github.com/Jxnas999" target="_blank" rel="noreferrer">
              <img
                src={require("../res/github.png")}
                className="ml-8 h-[90px] hover:-translate-y-2 duration-300"
                alt="github"
              />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
